import { Injectable } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MembershipAssociateVm } from '../share/membership-associate-form';
import { MembershipUpdateForm } from '../../store/membership-update';
import { ValidatorService } from '../share/form/validators/validator.service';

@Injectable({ providedIn: 'root' })
export class MembershipUpdateVm {
  formGroup = this.create();
  constructor(
    private fb: FormBuilder,
    private membershipAssociateVm: MembershipAssociateVm,
    private validator: ValidatorService,
  ) {}

  create() {
    return this.fb.group<MembershipUpdateForm>({
      firstName: new FormControl('', {
        nonNullable: true,
        validators: Validators.compose([Validators.required, this.validator.isValidName()]),
        updateOn: 'blur',
      }),
      lastName: new FormControl('', {
        nonNullable: true,
        validators: Validators.compose([Validators.required, this.validator.isValidName()]),
        updateOn: 'blur',
      }),
      accidentMedicalPlan: new FormControl(false, { nonNullable: true }),
      associates: this.membershipAssociateVm.create(),
      promoCode: new FormControl('', { nonNullable: true }),
      membershipType: new FormControl('', { nonNullable: true }),
    });
  }
}
